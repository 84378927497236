@font-face {
    font-family: 'HendersonBCGSans';
    src: url('./assets/fonts/HendersonBCGSans-Regular.woff') format('woff');
}

@font-face {
    font-family: 'HendersonBCGSans';
    font-weight: bold;
    src: url('./assets/fonts/HendersonBCGSans-Bold.woff') format('woff');
}

@font-face {
    font-family: 'HendersonBCGSans';
    font-weight: 300;
    src: url('./assets/fonts/HendersonBCGSans-Light.woff') format('woff');
}

body {
  margin: 0;
  font-family: 'HendersonBCGSans', 'Trebuchet MS', Helvetica, sans-serif;
  font-size: 16px;
  line-height: 1.8em;
  hyphens: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@media only screen and (min-width: 768px) {
  body {
    font-size: 18px;
  }
}

@media only screen and (min-width: 992px) {
  body {
    font-size: 20px;
  }
}
    
@media only screen and (min-width: 1200px) {
  body {
    font-size: 20px;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.msg-wrapper {
  white-space: pre-wrap;
}
